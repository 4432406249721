import React from "react";
// import Axios from 'axios';
//import $ from 'jquery';
import Api from "../Helper/Apiclass";
class Action extends React.Component {
  state = {
    activePage: "home",
    submitMessage:"",
  };

  applyForm =async (
    event,
    name,
    email,
    subject,
    number,
    address,
    education,
    my_file,
  ) => {
    event.preventDefault();
    event.persist();

      let api = new Api();
      let response = await api.applyForm(event, name,
        email,
        subject,
        number,
        address,
        education,
        my_file,);
		console.log("RES:",response);
		if(response === "success"){
              this.setState({
                submitMessage:"Application Submitted Successfully"
              });
              event.target.reset();
          }else{
              console.log(response);
          }
//      response.then(result => {
//          if(result === "success"){
//              this.setState({
//                submitMessage:"Application Submitted Successfully"
//              });
//              event.target.reset();
//          }else{
//              console.log(result);
//          }
//      });

  };

  openPage = (page) => {
    this.setState({
      activePage: page,
    });
  };
  // Game District fucnitons
  openNave = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };
  closeNave = () => {
    document.getElementById("mySidenav").style.width = "0";
  };
  tabs = (tabdevelop) => {
    var i;
    var x = document.getElementsByClassName("develop");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    document.getElementById(tabdevelop).style.display = "block";
  };
  tabs1 = (tabdesignd) => {
    var i;
    var x = document.getElementsByClassName("designdevelopment");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    document.getElementById(tabdesignd).style.display = "block";
  };
  tabs2 = (tabsupports) => {
    var i;
    var x = document.getElementsByClassName("supports");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    document.getElementById(tabsupports).style.display = "block";
  };

  openTab = (tabName, degree) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    // evt.currentTarget.className += " active";
    document.getElementById(tabName).className += " active";
  };
}
export default Action;
