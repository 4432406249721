import React, {Component} from 'react';
import {AppContext} from '../Context';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class About extends Component
{
	static contextType = AppContext;
	state= {
        responsive:{
			480:{
				items:4
			},
			600:{
				items:6
			}
		}
    }
    render(){
        let htmlData;
            htmlData = (
				
				<div className="fullpage">
				
<div className="about_section_1">
	<div className="container">
		<div className="about_section_inner">
		<div className="row">
			<div className="wow fadeInDown col-lg-12">
				<h1>We Give Wings To<br />Your Dreams</h1>
				<p>Game District longs for fresh & young minds who can mould their approaches with the changing challenges of the digital industry. </p>
			</div>
		</div>
		<div className="clr"></div>	
		<div className="row">
			<div className="col-lg-3">
			</div>
			<div className="col-lg-2">
				<div className="wow slideInLeft dreams">
					<h5>6<span>+</span></h5>
					<p>Years since inception</p>
				</div>
			</div>
			<div className="col-lg-2">
				<div className="wow bounceInUp  dreams">
					<h5>350<span>+</span></h5>
					<p>Strong Team</p>
				</div>
			</div>
			<div className="col-lg-2">
				<div className="wow slideInRight dreams">
					<h5>56K<span>+</span></h5>
					<p>Projects Delivered</p>
				</div>
			</div>
			<div className="col-lg-3">
			</div>
		</div>
		<div className="clr"></div>			
	</div>
	<div className="clr"></div>
</div>
</div>   
  

<div className="about_section_3">
	<div className="container">
		<div className="row">
			<div className="col-lg-12">	
				<div className="wow slideInDown whats_deives">
				 	<div className="whats_deives_title">
					 	<h1>What Drives Us?</h1>
					 	<h1 className="white">What Drives Us?</h1>
					 </div>
					 <p>Game District longs for fresh & young minds who can mould their<br />approaches with the changing.</p>
				</div>
			</div>
		<div className="clr"></div>
		<div className="col-lg-12">
		<div className="whatsdeives_area">
			<div className="wow slideInLeft whatsdeives_1">
				<div className="icon"><img alt="" src="./assets/img/icon_professionalism.jpg" /></div>
				<div className="whatsdeives_info">
					<h3>01</h3>
					<h6>Professionalism</h6>
					<p>Game District longs for fresh & young minds who can mould their approaches with the changing challenges of the digital industry.</p>
				</div>
			</div>
			<div className="wow slideInRight whatsdeives_1">
				<div className="icon"><img alt="" src="./assets/img/icon_love_for_work.jpg" /></div>
				<div className="whatsdeives_info">
					<h3>02</h3>
					<h6>Love For Work</h6>
					<p>Game District longs for fresh & young minds who can mould their approaches with the changing challenges of the digital industry.</p>
				</div>
			</div>
			<div className="clr"></div>
			<div className="wow slideInLeft whatsdeives_3">
				<div className="icon"><img alt="" src="./assets/img/icon_enthusiasm.jpg" /></div>
				<div className="whatsdeives_info">
					<h3>03</h3>
					<h6>Enthusiasm</h6>
					<p>Game District longs for fresh & young minds who can mould their approaches with the changing challenges of the digital industry.</p>
				</div>
			</div>
			<div className="wow slideInRight whatsdeives_3">
				<div className="icon"><img alt="" src="./assets/img/icon_exeeding_limits.jpg" /></div>
				<div className="whatsdeives_info">
					<h3>04</h3>
					<h6>Exceeding Limits</h6>
					<p>Game District longs for fresh & young minds who can mould their approaches with the changing challenges of the digital industry.</p>
				</div>
			</div>
		</div>
		<div className="clr"></div>	
		</div>
		</div>
		<div className="clr"></div>	
	</div> 
</div>         
    

<div className="about_section_4">
	<div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12">
        			<img className="like_title" alt="like-at-game-distric" src="./assets/img/like-at-game-district.png" />
					 <p>Game District longs for fresh & young minds who can mould their approaches with <br />the changing challenges of the digital industry. A growing mind is one of the cherished sights<br />for us as it’s a proof of one’s growth & success in the right direction.</p>
			<div className="clr"></div>	
			</div>
		</div>
		<div className="clr"></div>	
		<div className="row">
			<div className="col-lg-12">
				<div className="likes_gamedistrict">
				    <img className="icon_full" alt="" src="./assets/img/servicesiconsbg.jpg" />
					<div className="like_icons_raw1">
						<img className=" wow rollIn icon_1" alt="" src="./assets/img/icon-1.jpg" />
						<img className="wow bounceInDown icon_2" alt="" src="./assets/img/icon-6.jpg" />
						<img className="wow rollOut icon_3" alt="" src="./assets/img/icon-10.jpg" />
					</div>
					<div className="like_icons_raw1">
						<img className="wow slideInLeft icon_4" alt="" src="./assets/img/icon-2.jpg" />
						<img className="wow bounceInDown icon_5" alt="" src="./assets/img/icon-7.jpg" />
						<img className="wow bounceInDown icon_7" alt="" src="./assets/img/icon-11.jpg" />
						<img className="icon_8" alt="" src="./assets/img/icon-13.png" />
					</div>
					<div className="like_icons_raw1">
					<img className="icon_9" alt="" src="./assets/img/icon-3.jpg" />
						<img className="wow pulse icon_6"  alt="" src="./assets/img/icon-8.jpg" data-wow-iteration="3" />
					</div>
					<div className="like_icons_raw1">
						<img className="wow slideInLeft icon_10" alt="" src="./assets/img/icon-5.jpg" />
						<img className="wow bounceInDown icon_11" alt="" src="./assets/img/icon-9.jpg" />
						<img className="wow slideInRight icon_12" alt="" src="./assets/img/icon-12.jpg" />
						
					</div>
					<div className="like_icons_raw1">
						<img className="wow bounceInRight icon_13" alt="" src="./assets/img/icon-14.jpg" />
					</div>
				</div>
			</div>
		</div>
		<div className="clr"></div>	
	</div> 
</div>         
   
   
<div className="about_section_5">
	<div className="container">
		<div className="row">
			<div className="col-lg-12"> 
				<h1>Ready to start your project<span>?</span><a href="/about" className="start">Let’s Start</a></h1>
			</div>
		</div>
		<div className="clr"></div>	
	</div> 
</div>     
   
<div className="about_section_6">
	<div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12"> 
				<h1>Our Clients</h1>
				<p>Game District longs for fresh & young minds who can mould their <br />approaches with the changing.</p>
			</div>
			<div className="col-lg-12"> 
				<div className="our_clients">
					<img alt="" src="./assets/img/client-1.jpg" />
					<img alt="" src="./assets/img/client-2.jpg" />
					<img alt="" src="./assets/img/client-3.jpg" />
					<img alt="" src="./assets/img/client-5.jpg" /> 
				</div>
			</div>
			<div className="col-lg-12"> 
				<div className="our_clients">
					<img alt="" src="./assets/img/client-6.jpg" />
					<img alt="" src="./assets/img/client-7.jpg" />
					<img alt="" src="./assets/img/client-8.jpg" />
					<img alt="" src="./assets/img/client-9.jpg" /> 
				</div>
			</div>
		</div>
		<div className="clr"></div>	
	</div> 
</div>       
   
   
<div className="about_section_7">
	<div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12"> 
				<img alt="" src="./assets/img/game-district.jpg" />
			   <p>Dubai<span>-</span>SanFrancisco<span>-</span>Manama<span>-</span>Lahore</p>
			</div>
			<div className="dots"> 
			<img alt="" src="./assets/img/dots.jpg" />
			</div>
			<div className="col-lg-12"> 
				<div className="global_icon">
					<img alt="" src="./assets/img/global_icon_1.jpg" />
					<img alt="" src="./assets/img/global_icon_2.jpg" />
					<img alt="" src="./assets/img/global_icon_3.jpg" />
				</div>
			</div>
			<div className="col-lg-12"> 
				<div className="global_icon">
					<img alt="" src="./assets/img/global_icon_4.jpg" />
					<img alt="" src="./assets/img/global_icon_5.jpg" />
					<img alt="" src="./assets/img/global_icon_6.jpg" />
				</div>
			</div>
		</div>
		<div className="clr"></div>	
	</div> 
</div>  			      
			            
<div className="about_section_8">
	<div className="gallery_raw">
	    {/* <div id="inside-game-district2" className="owl-carousel"> */}
		<OwlCarousel className="owl-theme" items={7} dots={false} autoplay={true} autoplayTimeout={3000} responsive={this.state.responsive} >
    		<div className="item"><img alt="" src="./assets/img/gallery-1.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-2.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-3.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-4.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-5.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-6.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-1.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-2.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-3.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-4.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-5.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-6.jpg" /></div>
		{/* </div> */}
		</OwlCarousel>
		<div className="clr"></div>
	</div>
	<div className="gallery_raw2">
	    {/* <div id="inside-game-district" className="owl-carousel"> */}
		<OwlCarousel className="owl-theme" items={7} dots={false} autoplay={true} autoplayTimeout={3000} responsive={this.state.responsive} >
            <div className="item"><img alt="" src="./assets/img/gallery-7.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-8.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-9.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-10.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-11.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-12.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-13.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-7.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-8.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-9.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-10.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-11.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-12.jpg" /></div>
    		<div className="item"><img alt="" src="./assets/img/gallery-13.jpg" /></div>
		{/* </div> */}
		</OwlCarousel>
		<div className="clr"></div>
	</div>
</div>  
	<div className="clr"></div>
	</div>	
				
				
				
               

            );
      
        return (
        <>{htmlData}</>
        )
    }
}
export default About;