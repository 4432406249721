import React, { Component } from "react";
import { AppContext } from "../Context";
import {Link, withRouter} from 'react-router-dom';

class Careers extends Component {
  static contextType = AppContext;
  render() {
    let htmlData;
    htmlData = (
      <div className="fullpage">
        <div className="jobs_career_section_1">
          <img alt="" src="./assets/img/jobs_career_title.jpg" />
        </div>

        <div className="jobs_career_section_2">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="jobs_breadcrumb">
                  <ul>
                    <li>
                      <a href="careers">All Job Positions</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="wow bounceInUp jobs_arae">
                <div className="col-lg-3">
                  <h3>CG Artist</h3>
                  <div className="location">
                    Location: <b>Lahore</b>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p>
                   
					<Link to="/cg-artist">
                      Game District is an emerging Mobile Games Leader with 500+
                      Million Games Downloads and trusted by the Biggest
                      companies like NFL, Hobbits, LEGO, and Outfit7. We are
                      looking for a talented CG Artist to join our design team.
                    </Link>
                  </p>
                </div>
                <div className="col-lg-1">&ensp;</div>
                <div className="col-lg-2">
				<Link to="/cg-artist">
                  <a className="applybtn">
                    View Details
                  </a>
				</Link>
                </div>
                <div className="clr"></div>
              </div>

            
              <div className="wow bounceInUp jobs_arae">
                <div className="col-lg-3">
                  <h3>Unity Game Developer</h3>
                  <div className="location">
                    Location: <b>Lahore</b>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p>
		<Link to="/unity-game-developer">
                      We are looking for a talented Unity 3D Developer to join
                      our design team. As a Unity Developer, you will be
                      responsible for planning and implementing game
                      functionality, building the game code, identifying
                      bottlenecks, and ensuring the quality of the finished
                      product.
                    </Link>
                  </p>
                </div>
                <div className="col-lg-1">&ensp;</div>
                <div className="col-lg-2">
		<Link to="/unity-game-developer">
                  <a className="applybtn">
                    View Details
                  </a>
		</Link>
                </div>
                <div className="clr"></div>
              </div>

              <div className="clr"></div>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div>
    );
    return <>{htmlData}</>;
  }
}
export default Careers;
