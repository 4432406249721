import React from 'react';
import {Provider} from './Context';
import Header from './Components/Header';
import HomeBanner from './Components/HomeBanner';
import HomeSections from './Components/HomeSections';
import About from './Components/About';
import Solutions from './Components/Solutions';
import Publishing from './Components/Publishing';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import whygames from './Components/whyGames';
import careers from './Components/Careers';
import cgartist from './Components/CgArtist';
import uiuxdesigner from './Components/UiUxDesigner';
import unitygamedeveloper from './Components/UnityGameDeveloper';
import applicationform from './Components/ApplicationForm';


import Actions from './Actions/Actions';
import {Route,Switch,Redirect} from 'react-router-dom';
class App extends Actions{
    render(){
      const contextValue = {
        activePage:this.state.activePage,
        submitMessage:this.state.submitMessage,
        openNave:this.openNave,
        closeNave:this.closeNave,
        openPage:this.openPage,
        openTab:this.openTab,
        owlCarousel:this.owlCarousel,
		  tabs:this.tabs,
		  tabs1:this.tabs1,
		  tabs2:this.tabs2,
		  applyForm:this.applyForm,
      }
      return (
        <Provider value={contextValue}>
          <Header/>
          <Switch>
            <Route exact path='/' render={props =><><HomeBanner /><HomeSections /></>} />
            <Route path="/about" component={About}/>
            <Route path="/solutions" component={Solutions}/>
            <Route path="/publishing" component={Publishing}/>
            <Route path="/contact" component={Contact}/>
		  <Route path="/whygames" component={whygames}/>
		   <Route path="/careers" component={careers}/>
		  <Route path="/cg-artist" component={cgartist}/>
		  <Route path="/ui-ux-designer" component={uiuxdesigner}/>
		  <Route path="/unity-game-developer" component={unitygamedeveloper}/>
		  <Route path="/application-form" component={applicationform}/>
            <Redirect to="/" />
          </Switch>
          <Footer/>
        </Provider>
      )

    }
}


export default App;
