import React, { Component } from "react";
import { AppContext } from "../Context";
import {Link, withRouter} from 'react-router-dom';

class UiUxDesigner extends Component
{
    static contextType = AppContext;
    render(){
        let htmlData;
            htmlData = (
 <div className="fullpage">




<div className="jobs_career_section_2">					

	<div className="clr"></div>
<div className="container">
	
	<div className="row">
		<div className="col-lg-12"> 
			<div className="jobs_breadcrumb">
				<ul>
					<li><Link to="/careers">All Job Positions</Link></li>
					<li>>></li> 
					<li><a href="#">Ui / UX Designer</a></li>
				</ul>
			</div>
		</div>
	</div>		
	
    <div className="row">
		<div className="col-lg-4">
			 <div className="jobs_left_area">   
<h3>Ui / UX Designer</h3>
<p> Position: <b>Ui / UX Designer</b></p>
<p>Location: <b>Lahore</b></p>
<p>Minimum Experience: <b>2 to 3 Years</b></p>
<p>Job Type: <b>FULL TIME</b></p>
<p>Openings: <b>4</b></p>
 <div className="see_more_jobs">  
<Link to="/careers">See More Job Openings</Link>
<span>Having a profound Resume & compiled Portfolio increases your chances to ge hired 
at the desired position.</span>
</div>
</div>	
		</div>
		<div className="jobs_details_area col-lg-8">	
		  <h3>Company Description:</h3>
				<p>Game District is an emerging Mobile Games Leader with 500+ Million Games Downloads and trusted by the Biggest companies like NFL, Hobbits, LEGO, and Outfit7. We believe in making outstanding & engaging games that take the market by storm. Specializing in Casual, Rpg, Mid core, and Hyper casual games. </p>
		  <h3>Job Description:</h3>
<p>Game District is an emerging Mobile Games Leader with 500+ Million Games Downloads and trusted by the biggest companies like NFL, Hobbits, LEGO, and Outfit7. We are looking for a talented UI UX Designer to join our design team.</p>
<h3>Job Responsibilities:</h3>
<ul>
<li>UI design portfolio</li>
<li>Plan and implement new designs</li>
<li>Optimize existing user interface designs</li>
<li>Test for intuitively and experience</li>
<li>Combine creativity with an awareness of the design elements</li>
<li>Create prototypes for new product ideas</li>
<li>Conduct an ongoing user research</li>
<li>Excellent interpersonal and communication skills</li>
<li>Ability to discuss and explain design options</li>
<li>Must have proficiency with adobe suite</li>
<li>2 to 3 years of experience as an UI-UX Designer or similar role</li>
<li>Education: Bachelors</li> 
<li>Gender: Any</li>
</ul>
 
<div className="clr"></div>
    <Link to="/application-form"><a className="applybtn">APPLY NOW</a></Link>
		</div>
		</div>
		<div className="clr"></div>
	</div>
	<div className="clr"></div>	
</div></div>

            );
    return (<>{htmlData}</>);
    }
}
export default UiUxDesigner;