import React, { Component } from "react";
import { AppContext } from "../Context";
import {Link, withRouter} from 'react-router-dom';

class CgArtist extends Component
{
    static contextType = AppContext;
    render(){
        let htmlData;
            htmlData = (
 <div className="fullpage">




<div className="jobs_career_section_2">					

	<div className="clr"></div>
<div className="container">
	
	<div className="row">
		<div className="col-lg-12"> 
			<div className="jobs_breadcrumb">
				<ul>
					<li><Link to="/careers">All Job Positions</Link></li>
					<li>>></li> 
					<li><a href="#">CG Artist</a></li>
				</ul>
			</div>
		</div>
	</div>		
	
    <div className="row">
		<div className="col-lg-4">
			 <div className="jobs_left_area">   
<h3>CG Artist</h3>
<p> Position: <b>CG Artist</b></p>
<p>Location: <b>Lahore</b></p>
<p>Minimum Experience: <b>2 to 3 Years</b></p>
<p>Job Type: <b>FULL TIME</b></p>
<p>Openings: <b>4</b></p>
 <div className="see_more_jobs">  
<Link to="/careers">See More Job Openings</Link>
<span>Having a profound Resume & compiled Portfolio increases your chances to ge hired 
at the desired position.</span>
</div>
</div>	
		</div>
		<div className="jobs_details_area col-lg-8">	
		 <h3>Company Description:</h3>
				<p>Game District is an emerging Mobile Games Leader with 500+ Million Games Downloads and trusted by the Biggest companies like NFL, Hobbits, LEGO, and Outfit7. We believe in making outstanding & engaging games that take the market by storm. Specializing in Casual, Rpg, Mid core, and Hyper casual games. </p>
		  <h3>Job Description:</h3>
<p>Game District is an emerging Mobile Games Leader with 500+ Million Games Downloads and trusted by the Biggest companies like NFL, Hobbits, LEGO, and Outfit7. We are looking for a talented CG Artist to join our design team. 
Job Responsilbites:</p>

<ul>
<li>Create high-quality artwork for a game, such as the texture, characters, environment</li>
<li>Develop UI mockups and prototypes that clearly illustrate how Games function and look like</li>
<li>Combine creativity with an awareness of the design elements </li>
<li>Create original graphic designs (e.g. images, sketches, and tables) </li>
<li>Identify and troubleshoot UX problems (e.g. responsiveness) </li>
<li>Adhere to style standards on fonts, colors, and images</li>
<li>Expertise in 3D Modelling, Rigging, Game UI, Post Production and Game Icons </li>
<li>Proficiency in UV and Texturing</li>
<li>Capable of making game environments</li>
<li>Work closely with game designers, artists, and developer to create UI/UX design for our large-scale video games</li>
				</ul>
		<h3>Software Requirements:</h3>
		<ul>
			<li>Photoshop or Illustrator</li>
<li>3DS Max or Auto desk Maya (1 is Must)</li>
<li>Unity or Unreal Engine Must (1 is Must)</li>
<li>Arnold or V-Ray (1 is Must)</li>
<li>Blender is a plus point</li>
<li>Keyshot is a plus point</li>
<li>Marmoset toolbag is a plus point</li>
		</ul>	
 
<div className="clr"></div>
    <Link to="/application-form"><a className="applybtn">APPLY NOW</a></Link>
		</div>
		</div>
		<div className="clr"></div>
	</div>
	<div className="clr"></div>	
</div></div>

            );
    return (<>{htmlData}</>);
    }
}
export default CgArtist;