import React, {Component} from 'react';
import {AppContext} from '../Context';
import {Link, withRouter} from 'react-router-dom';
class Header extends Component
{
    static contextType = AppContext;

    render(){
        let htmlData;
        htmlData = (
            <div className="header">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="logo"><a href="/" ><img alt="Game District" src={"./assets/images/game-district.jpg"} /></a></div>
                        <div className="main_menu">
                        
                            <ul>
                                <li className={this.props.location.pathname === "/" ? "active" : ""}> <Link to="/">Home</Link> </li>
                                <li className={this.props.location.pathname === "/about" ? "active" : ""}> <Link to="/about">About</Link> </li>
                                <li className={this.props.location.pathname === "/solutions" ? "active" : ""}> <Link to="/solutions">Solutions</Link> </li>
                                <li className={this.props.location.pathname === "/publishing" ? "active" : ""}> <Link to="/publishing">Publishing</Link> </li>
                                <li className={this.props.location.pathname === "/whygames" ? "active" : ""}> <Link to="/whygames">Why Games</Link> </li>
                                <li className={this.props.location.pathname === "/careers" ? "active" : ""}> <Link to="/careers">Careers</Link> </li>
                                <li className={this.props.location.pathname === "/contact" ? "active" : ""}> <Link to="/contact">Contact</Link> </li>
								<li className={this.props.location.pathname === "/contact" ? "active" : ""}> <Link to={{ pathname: "https://inquiry.forms.app/gdpublishing/publish-your-game" }} target="_blank">Publish Your Game</Link> </li>
                            </ul>
                        </div>
                        <div className="mobile_menu"><span onClick={()=>this.context.openNave()}>&#9776; </span>
                            <div id="mySidenav" className="sidenav">
                                <span className="closebtn" onClick={()=>this.context.closeNave()}>&times;</span>
                                <ul>
                                    <li className='{router.pathname == "/" ? "active" : ""}'> <Link to="/">Home</Link> </li>
                                    <li className='{router.pathname == "about" ? "active" : ""}'> <Link to="/about">About</Link> </li>
                                    <li> <Link to="/solutions">Solutions</Link> </li>
                                    <li> <Link to="/publishing">Publishing</Link> </li>
                                    <li> <Link to="/brands">Brands</Link> </li>
                                    <li> <Link to="/careers">Careers</Link> </li>
                                    <li> <Link to="/contact">Contact</Link> </li>
									<li> <Link to={{ pathname: "https://inquiry.forms.app/gdpublishing/publish-your-game" }} target="_blank">Publish Your Game</Link>  </li>
                                </ul>
                            </div>
                        </div>	
                    </div>
                </div>
            </div>
        <div className="clr"></div>	
        </div>
        );
        return (
        <>{htmlData}</>
        );
    }
}
export default withRouter(Header);