import React, { Component } from "react";
import { AppContext } from "../Context";

class Contact extends Component
{
    static contextType = AppContext;
    render(){
        let htmlData;
            htmlData = (
 <div className="fullpage">
<div className="contactcontent">
	<div className="contact_section_1">
		<div className="container">
			<div className="row">
			    	<p>it’s not hard to find your</p>
					<h1>partners</h1>
				<div className="wow slideInLeft col-lg-5">				
				
					<div className="social_icons_contact">
						<a href="https://www.facebook.com/gamedistrict1/"><img src="./assets/img/facebook_icon.png" alt=""/></a>
						<a href="https://www.instagram.com/gamedistrict.co/"><img src="./assets/img/insta_icon.png" alt=""/></a>
						<a href="https://www.linkedin.com/company/game-district/about/"><img src="./assets/img/inn_icon.png" alt=""/></a>
						<a href="#youtube"><img src="./assets/img/youtube_icon.png" alt=""/></a>
					</div>
				{/*	<div className="contact_section_2_left">
						<h3>Get closer to<br />your epic<br />product.</h3>
						<h5>1. Proposal</h5>
						<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias</p>
						<h5>2. Discussion</h5>
						<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias</p>
						<h5>3. Execution</h5>
						<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias</p>
					</div>*/}
				</div>
				<div className="wow slideInRight col-lg-7">
					<div className="contact_section_2_right">

				<div className="contact_form_partners">
	<h4>Write something about your project so we can get back to you with a proposal in 24hours.</h4>
	<p><img className="mail_icon" src="./assets/img/mail_icon.png" alt=""/></p>
	<div className="clr"></div>
	<div role="form" className="wpcf7" id="wpcf7-f71-p37-o1" dir="ltr" lang="en-US">
	<form action="" method="post" className="wpcf7-form">
	<p><span className="wpcf7-form-control-wrap Name">
		<input type="text" name="Name" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"  placeholder="Name*" /></span>
		<span className="wpcf7-form-control-wrap EmailId">
			<input type="email" name="EmailId" size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"  placeholder="Email Id*"/></span>
			<span className="wpcf7-form-control-wrap CompanyName"><input type="text" name="CompanyName"  size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"  placeholder="Company Name*"/></span><span className="wpcf7-form-control-wrap ApproxBudget">
				<input type="text" name="ApproxBudget" size="40" className="wpcf7-form-control wpcf7-text" aria-invalid="false" placeholder="Approx Budget"/></span></p>
	<p><b>Optional</b></p>
	<p><span className="wpcf7-form-control-wrap ProjectDetails">
		<textarea name="ProjectDetails" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Project Details"></textarea></span></p>
	<div className="file"><span className="wpcf7-form-control-wrap Dropitlikeitshot"><input type="file" name="Dropitlikeitshot" size="40" className="wpcf7-form-control wpcf7-file drop_it" accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.ppt,.pptx,.odt,.avi,.ogg,.m4a,.mov,.mp3,.mp4,.mpg,.wav,.wmv" aria-invalid="false"/></span><label>Drop it like it’s hot</label></div>
	<p><input type="submit" value="submit your project" className="wpcf7-form-control wpcf7-submit"/></p>
	<div className="wpcf7-response-output wpcf7-display-none"></div></form></div></div>


					</div>
				</div>
			</div>
			<div className="clr"></div>	
		</div>
	</div>

</div>



<div className="about_section_7" style={{backgroundColor: "#f8f8f8"}} >
	<div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12"> 
				<img alt="" src="./assets/img/game-district2.jpg" />
			   <p>Dubai<span>-</span>SanFrancisco<span>-</span>Manama<span>-</span>Lahore</p>
			</div>
			<div className="dots"> 
			<img alt="" src="./assets/img/dots2.jpg" />
			</div>
			<div className="col-lg-12"> 
				<div className="global_icon">
					<img alt="" src="./assets/img/global_icon_contact_1.jpg" />
					<img alt="" src="./assets/img/global_icon_contact_2.jpg" />
					<img alt="" src="./assets/img/global_icon_contact_3.jpg" />
				</div>
			</div>
			<div className="col-lg-12"> 
				<div className="global_icon">
					<img alt="" src="./assets/img/global_icon_contact_4.jpg" />
					<img alt="" src="./assets/img/global_icon_contact_5.jpg" />
					<img alt="" src="./assets/img/global_icon_contact_6.jpg" />
				</div>
			</div>
		</div>
		<div className="clr"></div>	
	</div> 
</div>

</div>

            );
    return (<>{htmlData}</>);
    }
}
export default Contact;