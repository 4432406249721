import React, {Component} from 'react';
import {AppContext} from '../Context';
class HomeSections extends Component
{
    static contextType = AppContext;
    
    render(){
        let htmData;
            htmData = (
				<div className="fullpage">
				
<div className="home_section_publish">
	<div className="container">
		<div className="row">
		<div className="col-lg-12">
			<h3 className="wow slideInLeft">We Empower <span>Small Startups</span></h3>
		  <h3 className="wow slideInLeft">To Turn Into <span className="bg">Big Enterprises.</span></h3>
			<p className="wow slideInLeft">Our app solutions mutiply your ROI, enhance brand visibilty, and delight your customers.</p>
		  </div>
			</div>
			<div className="row">
			<div className="col-lg-7">
				<img className="wow slideInLeft" alt="" src="./assets/images/publish.jpg" />
				<a href="/publishing" className="wow slideOutUp learn_more">Publish with Us</a>
			</div>
			<div className="col-lg-5">
				<div className="wow slideInRight services_publish">
					<img alt="" src="./assets/img/icon-marketing.jpg" />
					<h4>Marketing Expertise</h4>
					<p>Turn a great idea into a product concept that is ready to start development</p>
				</div>
				<div className="wow slideInRight services_publish">
					<img alt="" src="./assets/img/icon-strategy.jpg" />
					<h4>Release Strategy</h4>
					<p>Features enough to satisfy early customers & provide feedback for future development.</p>
				</div>
				<div className="wow slideInRight services_publish">
					<img alt="" src="./assets/img/icon-management.jpg" />
					<h4>Community Management</h4>
					<p>Answer critical queries through research, design, prototyping, interviews & testing.</p>
				</div>
			</div>
			<div className="clr"></div>	
		</div>
		<div className="clr"></div>	
	</div>
</div>

<div className="home_our_journey">
	<div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12">	
				<h1>Our Journey  So Far</h1>
				<p>Since 2015, we have helped a number of start-ups<br />and big brands convert their digital ideas into engaging, feature rich apps & games.</p>
				<div className="trending_mobile_games"> <span>Building</span> <b>trending mobile games</b> <span>with</span></div>
				<img className="mplus" alt="" src="./assets/img/bullion.png" />
			</div>
		<div className="clr"></div>	
		</div>
		<div className="row">
			<div className="col-lg-12">	
				<div className="wow rollIn journey_plus">
					<img alt="" src="./assets/img/icon-published-game.jpg" />
					<h5>345<span>+</span></h5>
					<p>Published Games</p>
				</div>
				<div className="wow rollIn journey_plus">
					<img alt="" src="./assets/img/icon-strong-team.jpg" />
					<h5>350<span>+</span></h5>
					<p>Strong Team</p>
				</div>
				<div className="wow rollIn journey_plus">
					<img alt="" src="./assets/img/icon-projects-delivered.jpg" />
					<h5>56K<span>+</span></h5>
					<p>Projects Delivered</p>
				</div>
				<div className="wow rollIn journey_plus">
					<img alt="" src="./assets/img/icon-fortune.jpg" />
					<h5>6</h5>
					<p>Fortune 500 Clients</p>
				</div>
				<div className="wow rollIn journey_plus">
					<img alt="" src="./assets/img/icon-raisad.jpg" />
					<h5>$150<span>M</span></h5>
					<p>Raised by Clients</p>
				</div>
				<div className="learn_morearea">
					<a className="learn_more" href="#home">Learn More</a>
				</div>
			</div>
		<div className="clr"></div>	
		</div>	
	</div>	
</div>

<div className="home_best_clan">
	<div className="container">
		<div className="row">
			<div className="wow slideInLeft col-lg-4 col-sm-12">
				<img alt="" src="./assets/img/best-clan-left.jpg" />
			</div>
			<div className="wow slideInDown col-lg-4 col-sm-12">
				<h1>It’s the best clan you can find online</h1>
				<p>Game District longs for fresh & young minds who can mould their approaches with the changing challenges of the digital industry. A growing mind is one of the cherished sights for us as it’s a proof of growth & success in the right direction.</p>
				<a className="learn_more" href="/about">About Us</a>
			</div>
			<div className="wow slideInRight col-lg-4 col-sm-12">
				<img className="margin_top" alt="" src="./assets/img/best-clan-right.jpg" />
			</div>
		<div className="clr"></div>	
		</div>	
	</div>		
</div>			
	<div className="clr"></div>			
</div>			
            );
        return(
        <>{htmData}</>
        );
    }
}
export default HomeSections;