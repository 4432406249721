import React, {Component} from 'react';
import {AppContext} from '../Context';
// import $ from 'jquery';

class HomeBanner extends Component
{
    static contextType = AppContext;
    componentDidMount () {
        // your code here
    }
    render(){
        
        let htmlData;
            htmlData = (
				<div className="fullpage">
              <div className="home_section_gamesicon">
	<div className="container">
		<div className="row">
			<div className="col-lg-12">
				<img className="wow slideInLeft game_icon_1" src="./assets/img/game-icon-1.jpg" alt="game-icon-1" />
				<img className="wow slideInDown game-icon-s-1" src="./assets/img/game-icon-s-1.jpg" alt="game-icon-s-1" />
				<img className="wow slideInDown game-icon-s-1" src="./assets/img/game-icon-s-2.jpg" alt="game-icon-s-2" />
				<img className="wow slideInDown game-icon-s-1" src="./assets/img/game-icon-s-3.jpg" alt="game-icon-s-3" />
				<img className="wow slideInDown game-icon-s-1" src="./assets/img/game-icon-s-4.jpg" alt="game-icon-s-4" />
				<img className="wow slideInDown game-icon-s-2" src="./assets/img/game-icon-s-5.jpg" alt="game-icon-s-5" />		
				<img className="wow slideInRight game_icon_2" src="./assets/img/game-icon-2.jpg" alt="game-icon-2" />	
				<div className="clr"></div>
				<img className="wow slideOutUp game_icon_3" src="./assets/img/game-icon-3.jpg" alt="game-icon-3" />	
				<img className="wow slideOutUp game_icon_4" src="./assets/img/game-icon-4.jpg" alt="game-icon-4" />	
				<img className="wow slideOutUp game_icon_5" src="./assets/img/game-icon-5.jpg" alt="game-icon-5" />			
			</div>
		</div>
		<div className="clr"></div>	
	</div>
</div>
<div className="clr"></div>	
	</div>
            );
        return(
        <>{htmlData}</>
        )
    }
}
export default HomeBanner;