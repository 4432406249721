import React, {Component} from 'react';
import {AppContext} from '../Context';

class whyGames extends Component
{
    static contextType = AppContext;
    render(){
        let htmlData;
            htmlData = (
				
				<div className="fullpage">
<div className="whygames_section_1">
	<div className="container">
		<div className="whygames_section_inner">
		<div className="row">
			<div className="wow fadeInDown col-lg-12">
				<div className="whyicons"><img className="whyleft-icon" src="./assets/img/left-icon.jpg" alt="" /><img  className="whycenter-icon" src="./assets/img/icon-g.jpg" alt="" /><img className="whyright-icon" src="./assets/img/right-icon.jpg" alt="" /> </div>
				<p>Emerging mobile game leaders </p>
			</div>
		</div>
		<div className="clr"></div>			
	</div>
	<div className="clr"></div>
</div>
<div className="whygamesbg"></div>
</div>   
  
<div className="whygames_section_2">
   <div className="container">
		<div className="row">
			<div className="wow slideInLeft col-lg-3"><img src="./assets/img/why-client-1.jpg" alt="" /></div>
			<div className="wow slideInLeft col-lg-3"><img src="./assets/img/why-client-2.jpg" alt="" /></div>
			<div className="wow slideInLeft col-lg-3"><img src="./assets/img/why-client-3.jpg" alt="" /></div>
			<div className="wow slideInLeft col-lg-3"><img src="./assets/img/why-client-4.jpg" alt="" /></div>
		</div>
	</div>
	<div className="clr"></div>			
</div>  
   
<div className="whygames_section_3">
   <div className="container">
		<div className="row">
			<div className="col-lg-6">
				<div className="wow slideInLeft content_details">
				<h4>Create your next</h4>
				<h2>Viral Game App</h2>
				<p>85% of the top grossing apps are games. App Store growing at an unprecedented rate has made game development an opportunity of our decade. Turn your idea into reality and build the next viral mobile game.</p>
				</div>
			</div>
			<div className="col-lg-6">
				<div className="game_app">
					<div className=" game_icons">
						<img className="margin_icon_1 wow rollIn" src="./assets/img/game-icon-1.png" alt="" />
						<img className="margin_icon_2 wow rollIn" src="./assets/img/game-icon-2.png" alt="" />
						<img className="wow rollIn" src="./assets/img/game-icon-3.png" alt="" />
						<img className="margin_icon_3 wow rollIn" src="./assets/img/game-icon-4.png" alt="" />
					</div>
					 
				</div>
			</div>
			
		</div>
	</div>
	<div className="clr"></div>			
</div>     
   
<div className="whygames_section_4">
  	<img src="./assets/img/ring.png" alt="" className="ring_whygames" />
   <div className="container">
		<div className="row">
			<div className="wow slideInLeft col-lg-6">
  				<div className="era_img">   
   					<img src="./assets/img/era.jpg" alt="" />
   				</div>
			</div>
			<div className="wow slideInRight col-lg-6">   
   				<div className="text_area">
					<h4>the era of</h4>
					<h2>Mobile Games</h2>
					<p>With 2 Billion smartphone users around the world, App Store & Google Play revenues are expected to reach tens of billions of dollars in the next couple of years. Clearly, mobile is taking over a big part of the entertainment business traditionally generated by PCs & games console.</p>
					<p>This makes game app development a tremendous opportunity in the consumer technology revolution we are experiencing now. Not surprisingly, game apps represent the majority of revenues out of developers.</p>
				</div>
			</div>
		</div>	
	</div>
	<div className="clr"></div>			
</div>        
   

<div className="whygames_section_5">
   <div className="container">
		<div className="row">
			<div className="wow slideInLeft col-lg-6">
			<div className="text_2ndarea">
					<h4>the challenge of</h4>
					<h2>Delivering The Best</h2>
					<p>The rising numbers of mobile users & digital opinions has made game development a crucial & challenging task. But our team at Game District counters all the hurdles with professional approach & tactics.</p>
					<p>Separating the venture into Deisgn, Development & Publishing allows us to cater the various aspects of the game properly.  </p>
				</div>
			</div>
			<div className="wow slideInRight col-lg-6">
				<div className="services_delivering_raw">
					<div className="services_delivering margintop">
						<img src="./assets/img/icon-user-acquisition.jpg" alt="" />
						<h5>User Acquisition</h5>
						<p>If your customer acquisition costs are too high, you may not be able to sustain your business. Most successful game apps, such as Candy Crush or QuizUp, have hacked their growth by implementing smart referral and viral features.</p>
					</div>
				</div>
				<div className="services_delivering_raw">
					<div className="services_delivering">
						<img src="./assets/img/icon-engagement.jpg" alt="" />
						<h5>Engagement</h5>
						<p>Cryptocurrencies are no longer unregulated. Depending on their status and location, digital currency apps may have to comply with rules such as Anti-Money Laundering Programs, Suspicious Activity Reports etc.</p>
					</div>
					<div className="services_delivering">
						<img src="./assets/img/icon-monetization.jpg" alt="" />
						<h5>Monetization</h5>
						<p>How to monetize users is another frequent challenge game developers face. The industry has shifted from ads to in-app purchases, but building an efficient and sustainable business is not easy at all.</p>
					</div>
				</div>
			</div>
		</div>
			
	</div>
	<div className="clr"></div>			
</div>                 
    
<div className="whygames_section_6">
   <div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12">
				<div className="text_3rdarea">
					<h4>How can we help in</h4>
					<h2>Your Venture</h2>
					<p>In Game Development, achieving greatness is about turning your creative vision into a seamless and engaging player experience. That’s what makes a difference between awesomeness and mediocrity when it comes to engagement.</p>
					<p>However, your overall strategy should go way beyond development aspect. Although your product strategy should be about crafting an amazing user experience, it needs to think about implementing an effective monetization and business strategy.  </p>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-lg-12">
				<div className="venture_icon">
					<img className="margin_icon_1 wow rollIn" src="./assets/img/venture-icon-1.jpg" alt="" />
					<img className="margin_icon_2 wow rollIn"  src="./assets/img/venture-icon-2.jpg" alt="" />
					<img className="margin_icon_3 wow rollIn"  src="./assets/img/venture-icon-3.jpg" alt="" />
				</div>
			</div>
		</div>
	</div>
	<div className="clr"></div>			
</div> 
			    
<div className="whygames_section_7">
  <div className="whygames_section_7inner">
   <div className="container">
		<div className="row">
			<div className="wow slideInLeft col-lg-12">
					<div className="text_4tharea">
					<h4>It all starts with</h4>
					<h2>The Right Strategy</h2>
					<p>The quality of game design ultimately depends on creativty and vision. But that represents only part of the story. Tranforming this creative vision into an engaging and semaless user experience, while building a viable business model on top of it, is what separates mediocrity from greatness.</p>
					<p>At Game District, we undrestand this. Our product managers worked on a wide range of top selling game apps, which cumulatively achieved tens of millions of downloads. </p>
					<p>From fleshing out the initial creative idea, building an exciting storyboard and crafting an amazing user epxerience to building a viable monetization energy, our product manager will work with you on every aspect of your game.</p>
				</div>                        
			</div>
		</div>
	</div>
	<div className="clr"></div>			
</div>                   
	<div className="clr"></div>			
</div>                        
    
    
 <div className="home_section_inside_gamedistrict">
	<div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12">
				<img alt="" src="./assets/img/inside.jpg" />
				<p>Game District longs for fresh & young minds who can mould their approaches with the changing challenges of the digital industry. A growing mind is one of the cherished sights for us as it’s a proof of growth & success in the right direction.</p>
			</div>
		</div>
		<div className="clr"></div>	
	</div>
	<div className="wow slideOutUp inside_gamedistrict_pic">
		<div className="inside_img">
			<img src="./assets/img/inside-g-1.jpg" alt="" />
			<img src="./assets/img/inside-g-2.jpg" alt="" />
		</div>
		<div className="inside_img2">
			<img src="./assets/img/inside-g-3.jpg" alt="" />
			<img src="./assets/img/inside-g-4.jpg" alt="" />
		</div>
		<div className="inside_img3">
			<img src="./assets/img/inside-g-5.jpg" alt="" />
			<img src="./assets/img/inside-g-6.jpg" alt="" />
		</div>
		<div className="inside_img4">
			<img src="./assets/img/inside-g-7.jpg" alt="" />
			<img src="./assets/img/inside-g-8.jpg" alt="" />
		</div>
		<div className="inside_img5">
			<img src="./assets/img/inside-g-9.jpg" alt="" />
			<img src="./assets/img/inside-g-10.jpg" alt="" />
		</div>
	</div>
</div>	  

<div className="home_section_joinus">
	<div className="container">
		<div className="row">
			<div className="col-lg-12">
				<a className="apply_now" href="/whygames">Apply Now</a>
			</div>
		</div>
		<div className="clr"></div>	
	</div>
</div>	    
   <div className="clr"></div>
	</div>	
				
				
				
               

            );
      
        return (
        <>{htmlData}</>
        )
    }
}
export default whyGames;