import React, {Component} from 'react';
import {AppContext} from '../Context';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class Solutions extends Component
{
	static contextType = AppContext;
	state= {
        responsive:{
			480:{
				items:2
			},
			600:{
				items:3
			}
		}
    }
    render(){
        let htmlData;
            htmlData = (
                <div className="solutoinscontent">
				<div className="solutions_section_1">
	<div className="container">
		<div className="row">
			<div className="wow fadeInDown col-lg-6">
				<h1>Custom Solutions<br />for your game app</h1>
				<p>This booming gaming industry requires businessmen to explore & collaborate with other ventures and dictate their future more firmly. Latest technologies like artificial intelligence (AI) and virtual reality (VR) create a futuristic impact & really captures the eye of digitally prone users. Game District is always on toes to traverse every possible opportunity to collaborate, innovate & invent complex ideas & their contrary execution for the users.  </p>
				<a href="#solution" className="wow slideOutUp learn_more">Learn More</a>
			
			</div>
			<div className="wow fadeInDown col-lg-6">
			    <div className="solutions_right_icon">
				    <img alt="" src="./assets/img/solutions_right.png" />
				</div>
			</div>
		</div>
		<div className="clr"></div>	
		<div className="row">
			<div className="col-lg-12">
				<div className="wow slideInLeft solutions_icons">
				    <img alt="" src="./assets/img/solutions_icon_1.png" />
				</div>
				<div className="wow slideInLeft solutions_icons">
				    <img alt="" src="./assets/img/solutions_icon_2.png" />
				</div>
				<div className="wow slideInLeft solutions_icons">
				    <img alt="" src="./assets/img/solutions_icon_3.png" />
				</div>
				<div className="wow slideInLeft solutions_icons">
				    <img alt="" src="./assets/img/solutions_icon_4.png" />
				</div>
				<div className="wow slideInLeft solutions_icons">
				    <img alt="" src="./assets/img/solutions_icon_5.png" />
				</div>
			</div>
		</div>
		<div className="clr"></div>			
	</div>
	<div className="clr"></div>
</div>
				
<div class="solutions_section_2">
	<div class="container">
		<div class="row">
			<div class="wow slideInDown col-lg-12"> 
			    <h1>Develop a better and secure<br />experience for your users</h1>
			    <p></p>
			</div>
		</div>
		<div class="clr"></div>	
		<div class="row">
		    <div class="row_padding">
			<div class="wow slideInLeft col-lg-4"> 
<div class="tabbar_top">
    <a onClick={()=>this.context.tabs('ideation')} href="#solution">	
        <span><img alt="" src="./assets/img/develop_icon_1.png" /></span><br />
        ideation<img class="arrow" alt="" src="./assets/img/arrow.png" />
    </a>
    <a onClick={()=>this.context.tabs('strategy')} href="#solution">
        <span><img alt="" src="./assets/img/develop_icon_2.png" /></span><br />
        strategy <img class="arrow" alt="" src="./assets/img/arrow.png" /></a>
    <a onClick={()=>this.context.tabs('architecture')} href="#solution">
        <span><img alt="" src="./assets/img/develop_icon_3.png" /></span><br />
        architecture</a>
</div>

<div id="ideation" class="develop">
  <h3>Required Gathering</h3>
<ul>
	<li>Work Breakdown Structure</li>
	<li>Project Plan</li>
	<li>Project Budget</li>
	<li>Requirements Docs</li>
	<li>Use Cases</li>
	<li>Use Stories</li>
</ul>
</div>

<div id="strategy" class="develop" style={{display:"none"}}>
    <h3>Required Strategy</h3>
    <ul>
    	<li>Work Breakdown Structure</li>
    	<li>Project Plan</li>
    	<li>Project Budget</li>
    	<li>Requirements Docs</li>
    	<li>Use Cases</li>
    </ul>
</div>

<div id="architecture" class="develop" style={{display:"none"}}>
    <h3>Required Architecture</h3>
    <ul>
    	<li>Work Breakdown Structure</li>
    	<li>Project Plan</li>
    	<li>Project Budget</li>
    	<li>Requirements Docs</li>
    	<li>Use Cases</li>
    	<li>Use Stories</li>
    </ul>
</div>


			</div>
			<div class="wow bounceInUp col-lg-4"> 
			    
			    
<div class="tabbar_top">
    <a onClick={()=>this.context.tabs1('design')} href="#solution">
        <span><img alt="" src="./assets/img/develop_icon_4.png" /></span><br />
        design<img class="arrow" alt="" src="./assets/img/arrow.png" />
    </a>
    <a onClick={()=>this.context.tabs1('development')} href="#solution">
        <span><img alt="" src="./assets/img/develop_icon_5.png" /></span><br />
        development <img class="arrow" alt="" src="./assets/img/arrow.png" /></a>
    <a onClick={()=>this.context.tabs1('testing')} href="#solution">
        <span><img alt="" src="./assets/img/develop_icon_6.png" /></span><br />
        testing</a>
</div>

<div id="design" class="designdevelopment">
    <h3>Design & Development</h3>
    <ul>
    	<li>Low-Fi Design</li>
    	<li>Hi-Fi Design</li>
    	<li>User Experience Design</li>
    	<li>Source Code</li>
    	<li>Compiled Code</li>
    	<li>Code Documentation</li>
    </ul>
</div>

<div id="development" class="designdevelopment" style={{display:"none"}}>
    <h3>Design & Development</h3>
    <ul>
    	<li>Low-Fi Design</li>
    	<li>Hi-Fi Design</li>
    	<li>User Experience Design</li>
    	<li>Compiled Code</li>
    	<li>Code Documentation</li>
    </ul>
</div>

<div id="testing" class="designdevelopment" style={{display:"none"}}>
    <h3>Design & Development</h3>
    <ul>
    	<li>Low-Fi Design</li>
    	<li>Hi-Fi Design</li>
    	<li>User Experience Design</li>
    	<li>Source Code</li>
    	<li>Compiled Code</li>
    	<li>Code Documentation</li>
    </ul>
</div>

			</div>
			<div class="wow slideInRight col-lg-4"> 
<div class="tabbar_top">
    <a onClick={()=>this.context.tabs2('release')} href="#solution">
        <span><img alt="" src="./assets/img/develop_icon_7.png" /></span><br />
        release<img class="arrow" alt="" src="./assets/img/arrow.png" />
    </a>
    <a onClick={()=>this.context.tabs2('support')} href="#solution">
        <span><img alt="" src="./assets/img/develop_icon_8.png" /></span><br />
        support </a>
</div>

<div id="release" class="supports">
  <h3>Delivery & Support</h3>
    <ul>
        <li>Release Management</li>
    	<li>Change Management</li>
    	<li>User Docs & Training</li>
    	<li>Scheduled Maintenance</li>
    	<li>Adaptive Maintenance</li>
    	<li>Software Roadmap</li>
    </ul>
</div>

<div id="support" class="supports" style={{display:"none"}}>
    <h3>Delivery & Support</h3>
    <ul>
    	<li>Release Management</li>
    	<li>Change Management</li>
    	<li>User Docs & Training</li>
    	<li>Scheduled Maintenance</li>
    	<li>Adaptive Maintenance</li>
    </ul>
</div>
			</div>
			<div class="clr"></div>	
				</div>
		</div>
		<div class="clr"></div>	
	</div> 
</div>
 <div className="solutions_section_3">
	<div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12"> 
				<h1>Ready to start your project<span>?</span><a href="/solutions" className="start">Let’s Start</a></h1>
			</div>
		</div>
		<div className="clr"></div>	
	</div> 
</div>    
				
<div className="solutions_section_4">
	<div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12"> 
			<h1>Businesses That Trust Us</h1>
			    <p></p>
			</div>
		</div>
		<div className="clr"></div>	
		<div className="row">
		    <div className="row_padding2">
			<div className="wow slideInLeft col-lg-4"> 
			<img alt="" src="./assets/img/startups_icon.jpg" />
			<h3>Startups</h3>
			    <ul>
			        <li>MVP for quick launch to market</li>
			        <li>Fixed price estimates for clear budgeting</li>
			        <li>Agile practices to accelerate project delivery</li>
			    </ul>
			</div>
			<div className="wow bounceInUp col-lg-4"> 
			<img alt="" src="./assets/img/smbs_icon.jpg" />
			<h3>SMBs</h3>
			    <ul>
			        <li>Flexibility engagement models</li>
			        <li>In-dpeth domain epxertise & industry knowledge</li>
			        <li>Tried & Tested environemtn optimization methods</li>
			    </ul>
			</div>
			<div className="wow slideInRight col-lg-4">
			    <img alt="" src="./assets/img/enterprise_icon.jpg" /> 
			<h3>Enterprise</h3>
			    <ul>
			        <li>Consultancy to chalk out a future roadmap</li>
			        <li>Compliance with industry standards & regulations</li>
			        <li>Leverage emerging technologies</li>
			    </ul>
			</div>
		</div>
		<div className="clr"></div>	
		</div>
		<div className="clr"></div>	
	</div> 
</div>				
  
    <div className="solutions_section_5">
    <div className="solutions_section_5_inner">  
	<div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12">  
                <h1>We Serve The Inception</h1>
                <p>We publish your project in the best manner covering all the important aspects that boosts<br /> your game reach and ultimately gives you better results. Your game has to be <br />attractive with the potential to become a leading venture.</p>
            </div>
		<div className="clr"></div>	
		</div>
		
		<div className="row">
			<div className="col-lg-12">  
            	<div className="wow slideInLeft inception_details">
            	 <div className="inception_icons">
            	    <img alt="" src="./assets/img/icon_inception_1.jpg" />
            	</div> 
            	<div className="inception_detailsinner">
            	    <div className="nombers">01</div>
            	    <h3>App Discovery</h3>
            	    <p>App Discovery plays an important role in bringing up your project to the right users. Automatically analyzing artifacts of a software application and determining metadata structures associated with the application proves to be a very handy trick to improve your presence on the app stores.</p>
            	</div> 
            	</div>  
            	<div className="wow slideInRight inception_detailsright">
            	 <div className="inception_icons">
            	    <img alt="" src="./assets/img/icon_inception_2.jpg" />
            	</div> 
            	<div className="inception_detailsinner">
            	    <div className="nombers">02</div>
            	    <h3>Game Design</h3>
            	    <p>Game Design is quite dependent on the usability of the interface. The experience taken in by the user dictates the success or failure of a game or an app. To counter that, every successful venture has put a lot of thought & effort in designing the perfectly smooth UI & UX for their product as it is one of the prominent features any victorious venture.</p>
            	</div> 
            	</div> 
            	<div className="wow slideInLeft inception_details">
            	 <div className="inception_icons">
            	    <img alt="" src="./assets/img/icon_inception_3.jpg" />
            	</div> 
            	<div className="inception_detailsinner">
            	    <div className="nombers">03</div>
            	    <h3>Game Art</h3>
            	    <p>Gaming industry has users from all age groups & approaches, and for that all the present genres of the world come into play one way or another. Determining the design niche & genre according to the target audience defines the type of Game Art that may go perfectly with the concept of the game. Merger of a strong idea & a perfect mood depiction it what a perfect game has in it.</p>
            	</div> 
            	</div> 
            		<div className="wow slideInRight inception_detailsright">
            	 <div className="inception_icons">
            	    <img alt="" src="./assets/img/icon_inception_4.jpg" />
            	</div> 
            	<div className="inception_detailsinner">
            	    <div className="nombers">04</div>
            	    <h3>App Search Optimization</h3>
            	    <p>App search optimisation brings your game to the top levels of discovery in the app stores but it has much more to it. The speed, smoothness and size of your app also comes in to play when you’re trying to reach maximum levels. Our dedicated provides all the solutions one might need to make a robustly optimised game.</p>
            	</div> 
            	</div> 
            		<div className="wow slideInLeft inception_details">
            	 <div className="inception_icons">
            	    <img alt="" src="./assets/img/icon_inception_5.jpg" />
            	</div> 
            	<div className="inception_detailsinner">
            	    <div className="nombers">05</div>
            	    <h3>App Marketing</h3>
            	    <p>Marketing is one of the major elements of a successful business of any kind. Letting people know about your products through google adverts and in-app adverts creates a sense of solid existence, consequently boosting the brand image & likeability of a purchase.</p>
            	</div> 
            	</div> 
            </div>
		<div className="clr"></div>	
		</div>
		<div className="clr"></div>	
	</div> 
		<div className="clr"></div>	
	</div> 
</div>  
   



 <div className="solutions_section_6">
	<div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12">  
                <h1>Featured Work</h1>
                <p></p>
            </div>
		<div className="clr"></div>	
		</div>
		
		<div className="row">
		   {/* <div id="featurework" className="owl-carousel"> */}
		   <OwlCarousel id="featurework" className="owl-theme" items={3} dots={true} autoplay={true} autoplayTimeout={3000} responsive={this.state.responsive} >
    		<div className="item">
    		    <img alt="" src="./assets/img/feature_work_slide_1.jpg" />
    		    <div className="slide_info">
                <h3>Explore the curiosity of the CandyLand.</h3>
                <p></p>
                <a href="/solutions">View Project</a>
                </div>
    		</div>
    		<div className="item">
    		    <img alt="" src="./assets/img/feature_work_slide_2.jpg" />
    		    <div className="slide_info">
                <h3>Explore the curiosity of the CandyLand.</h3>
                <p></p>
                <a href="/solutions">View Project</a>
                </div>
    		</div>
    		<div className="item">
    		    <img alt="" src="./assets/img/feature_work_slide_3.jpg" />
    		    <div className="slide_info">
                <h3>Explore the curiosity of the CandyLand.</h3>
                <p></p>
                <a href="/solutions">View Project</a>
                </div>
    		</div>
    		<div className="item">
    		    <img alt="" src="./assets/img/feature_work_slide_1.jpg" />
    		    <div className="slide_info">
                <h3>Explore the curiosity of the CandyLand.</h3>
                <p></p>
                <a href="/solutions">View Project</a>
                </div>
    		</div>
    		<div className="item">
    		    <img alt="" src="./assets/img/feature_work_slide_2.jpg" />
    		    <div className="slide_info">
                <h3>Explore the curiosity of the CandyLand.</h3>
                <p></p>
                <a href="/solutions">View Project</a>
                </div>
    		</div>
    		<div className="item">
    		    <img alt="" src="./assets/img/feature_work_slide_3.jpg" />
    		    <div className="slide_info">
                <h3>Explore the curiosity of the CandyLand.</h3>
                <p></p>
                <a href="/solutions">View Project</a>
                </div>
    		</div>
    		<div className="item">
    		    <img alt="" src="./assets/img/feature_work_slide_1.jpg" />
    		    <div className="slide_info">
                <h3>Explore the curiosity of the CandyLand.</h3>
                <p></p>
                <a href="/solutions">View Project</a>
                </div>
    		</div>
    		<div className="item">
    		    <img alt="" src="./assets/img/feature_work_slide_2.jpg" />
    		    <div className="slide_info">
                <h3>Explore the curiosity of the CandyLand.</h3>
                <p></p>
                <a href="/solutions">View Project</a>
                </div>
    		</div>
    		<div className="item">
    		    <img alt="" src="./assets/img/feature_work_slide_3.jpg" />
    		    <div className="slide_info">
                <h3>Explore the curiosity of the CandyLand.</h3>
                <p></p>
                <a href="/solutions">View Project</a>
                </div>
    		</div>
    	
		{/* </div>  */}
		    </OwlCarousel>
		<div className="clr"></div>	
	</div> 
		<div className="clr"></div>	
	</div> 
</div>  




<div className="solutions_section_7">
	<div className="container">
		<div className="row">
			<div className="wow slideInDown col-lg-12">  
                <h1>We Know All The Techs</h1>
                <p>Game District is always on toes to traverse every<br />possible opportunity to collaborate</p>
            </div>
		<div className="clr"></div>	
            <div className="wow bounceInUp techs_icons">
                <img alt="" src="./assets/img/techs_icon_1.png" />
            </div>
            <div className="wow bounceInUp techs_icons">
                <img alt="" src="./assets/img/techs_icon_2.png" />
            </div>
            <div className="wow bounceInUp techs_icons">
                <img alt="" src="./assets/img/techs_icon_3.png" />
            </div>
	
	    </div> 
	    <div className="clr"></div>	
	</div> 
</div>                         
                   <div className="clr"></div>
                </div>
            );


        return (
        <>{htmlData}</>
        );


    }
}
export default Solutions;