import Axios from "axios";
class Apiclass {
  applyForm =async (
    event,
    name,
    email,
    subject,
    number,
    address,
    education,
    my_file
  ) => {
	 const URL = "https://gamedistrict.co/email/mail.php"; 
//	 const URL = "http://localhost/gamedistrict-react/email/mail.php";
    var formData = new FormData();
    formData.append('name',name);
    formData.append('email',email);
    formData.append('subject',subject);
    formData.append('number',number);
    formData.append('address',address);
    formData.append('education',education);
    formData.append('my_file',my_file);
	  
//	const response = await fetch(URL, {
//    method: 'POST',
//    headers: {
//      'Content-Type': 'application/json',
//      'X-Requested-With': 'XMLHttpRequest',
//    },
//    body: JSON.stringify(formData),
//  });
//  let res = await response.json();
//	  console.log("R:".res)
    const res = await Axios.post(
      URL,
      formData
    );
	  console.log("API RES:", res);
	  
//      res.then(function ({ data }) {
//        return data;
//      })
//      .catch(function (error) {
//        console.log("ERROR:",error);
//        return error;
//      });
	  return res.data;
  };
}
export default Apiclass;
