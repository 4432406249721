import React, { Component } from "react";
import { AppContext } from "../Context";
import {Link, withRouter} from 'react-router-dom';

class UnityGameDeveloper extends Component
{
    static contextType = AppContext;
    render(){
        let htmlData;
            htmlData = (
 <div className="fullpage">




<div className="jobs_career_section_2">					

	<div className="clr"></div>
<div className="container">
	
	<div className="row">
		<div className="col-lg-12"> 
			<div className="jobs_breadcrumb">
				<ul>
					<li><Link to="/careers">All Job Positions</Link></li>
					<li>>></li> 
					<li><a href="#">Unity Game Developer</a></li>
				</ul>
			</div>
		</div>
	</div>		
	
    <div className="row">
		<div className="col-lg-4">
			 <div className="jobs_left_area">   
<h3>Unity Game Developer</h3>
<p> Position: <b>Unity Game Developer</b></p>
<p>Location: <b>Lahore</b></p>
<p>Minimum Experience: <b>2 to 3 Years</b></p>
<p>Job Type: <b>FULL TIME</b></p>
<p>Openings: <b>4</b></p>
 <div className="see_more_jobs">  
<Link to="/careers">See More Job Openings</Link>
<span>Having a profound Resume & compiled Portfolio increases your chances to ge hired 
at the desired position.</span>
</div>
</div>	
		</div>
		<div className="jobs_details_area col-lg-8">
		<h3>Company Description:</h3>
				<p>Game District is an emerging Mobile Games Leader with 500+ Million Games Downloads and trusted by the Biggest companies like NFL, Hobbits, LEGO, and Outfit7. We believe in making outstanding & engaging games that take the market by storm. Specializing in Casual, Rpg, Mid core, and Hyper casual games. </p>
		  <h3>Job Description:</h3>
<p>We are looking for a talented Unity 3D Developer to join our design team. As a Unity Developer, you will be responsible for planning and implementing game functionality, building the game code, identifying bottlenecks, and ensuring the quality of the finished product. </p>
<h3>Job Responsilbites:</h3>
<ul>
<li>Meeting with the design team to discuss gaming ideas and specifications.</li>
<li>Establishing strategy and development pipelines.</li>
<li>Planning and implementing game functionality.</li>
<li>Transforming design ideas into functional games.</li>
<li>Designing and building game codes.</li>
<li>Identifying and fixing code errors and game bottlenecks.</li>
<li>Testing game functionality and theme dynamics.</li>
<li>Ensuring products conform to high industry standards.</li>
<li>Designing and building game patches and upgrades.</li>
</ul>
<h3>Job Requirements:</h3>
				<ul>
<li>Bachelor’s degree in Computer Science, Computer Engineering or similar field.</li>
<li>3-4 years of experience working as a Unity 3D Developer.</li>
<li>In-depth knowledge of Unity and Unity 3D software.</li>
<li>Knowledge of 3D development and integrated game physics.</li>
<li>Experience with console and mobile game development.</li>
<li>Good communication skills.</li>
<li>Experience with memory and space optimization.</li>
<li>Knowledge of scripting, animation, session management, and textures.</li>
<li>Good troubleshooting skills.</li>
				</ul>
 
<div className="clr"></div>
    <Link to="/application-form"><a className="applybtn">APPLY NOW</a></Link>
		</div>
		</div>
		<div className="clr"></div>
	</div>
	<div className="clr"></div>	
</div></div>

            );
    return (<>{htmlData}</>);
    }
}
export default UnityGameDeveloper;