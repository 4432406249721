import React, { Component,useState  } from "react";
import { AppContext } from "../Context";
import {Link, withRouter} from 'react-router-dom';

class ApplicationForm extends Component {
	state = {
		selected_file: null
	  };

  static contextType = AppContext;
  applyForm = (event) => {
    this.context.applyForm(
      event,
      this.FullName.value,
      this.EmailID.value,
      this.subject.value,
      this.ContactNumber.value,
      this.address.value,
      this.Education.value,
      this.state.selected_file,
    );
  };

  changeFile = (event) =>{
	this.setState({
		selected_file:event.target.files[0]
	});
  }

  render() {
    let breadcrumb = ">>";
    let htmlData;
    let mresmessage = this.context.submitMessage;
    htmlData = (
      <div className="fullpage">
        <div className="jobs_career_section_2">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="jobs_breadcrumb">
                  <ul>
                    <li>
						<Link to="/careers">All Job Positions</Link>
                    </li>
                    <li>{breadcrumb}</li>
                    <li>
                      <a href="#">Application Form</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7">
                <img alt="" src="./assets/img/jobs_career_profilepic.jpg" />
              </div>
              <div className="col-lg-5">
                <h3 className="application_title">Application Form</h3>

	<div class="iframe"> 
  <iframe class="responsive-iframe" src="https://gamedistrict.co/blog/application-form"></iframe>
</div>	
               
                <div className="clr"></div>
              </div>
            </div>
            <div className="clr"></div>
          </div>
        </div>
        <div className="clr"></div>
      </div>
    );
    return <>{htmlData}</>;
  }
}
export default ApplicationForm;
